.wrapper {
  white-space: nowrap;
  overflow: scroll;
  scroll-snap-type: x mandatory;
  aspect-ratio: 2 / 1;

  &::-webkit-scrollbar {
    display: none;
  }
}

.item {
  display: inline-block;
  width: 100%;
  scroll-snap-align: start;
  padding: 10px;
  border-radius: 10px;
  background: #222222;
  box-shadow: inset 0 -100px 50px -50px rgba(0, 0, 0, 0.8);
  background-size: cover;
  background-paosition: center center;
  height: 100%;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.headline {
  position: absolute;
  bottom: 10px;
  white-space: normal;
}
