:root {
  --border-color: #2a2a2a;
  --primary-color: #323131;
}

body {
  cursor: default;
  user-select: none;
  overscroll-behavior: none;
  margin: 0;
  background: #000;
  color: #fff;
  font-family: "Outfit", sans-serif;
}

* {
  outline: none;
  box-sizing: border-box;
  position: relative;
}
