.root {
  background: var(--primary-color);
  color: #fff;
  border-radius: 5px;
  height: 40px;
  margin-bottom: 20px;
  display: flex;
}

.input {
  background: none;
  padding: 0 10px;
  border: none;
  line-height: 40px;
  color: #fff;
  flex: 1;
}

.qrButton {
  background: none;
  border: none;
  color: #fff;
  line-height: 40px;
  padding: 0 10px;

  span {
    line-height: 40px;
  }
}
